import { ShiftModel } from "../../../../shared/models/shift.model";
import Api from "../../../../shared/services/api"
import { UsageEfficiencyRequest, EfficiencyData, WorkingEfficiencyRequest, MovementEfficiencyRequest } from "../models/efficiency.model";

export const getUsageData = async (usageEfficiencyRequest: UsageEfficiencyRequest): Promise<EfficiencyData[]> => {
    const result = await Api.get(`/reports/usage-efficiency?${usageEfficiencyRequest.organizationIds.map((n) => `organizationIdList=${n}`).join('&')}&${usageEfficiencyRequest.companyIds.map((n) => `companyIdList=${n}`).join('&')}&${usageEfficiencyRequest.forkliftIds.map((n) => `forkliftIdList=${n}`).join('&')}&${usageEfficiencyRequest.departmentIds.map((n) => `departmentIdList=${n}`).join('&')}&${usageEfficiencyRequest.modelIds.map((n) => `modelList=${n}`).join('&')}&${usageEfficiencyRequest.activityIds.map((n) => `activityList=${n}`).join('&')}&startDate=${usageEfficiencyRequest.startDate}&endDate=${usageEfficiencyRequest.endDate}`);
    return result.data;
}

export const getWorkingData = async (workingEfficiencyRequest: WorkingEfficiencyRequest): Promise<EfficiencyData[]> => {
    const result = await Api.get(`/reports/working-efficiency-v2?${workingEfficiencyRequest.organizationIds.map((n) => `organizationIdList=${n}`).join('&')}&${workingEfficiencyRequest.companyIds.map((n) => `companyIdList=${n}`).join('&')}&${workingEfficiencyRequest.forkliftIds.map((n) => `forkliftIdList=${n}`).join('&')}&${workingEfficiencyRequest.departmentIds.map((n) => `departmentIdList=${n}`).join('&')}&${workingEfficiencyRequest.modelIds.map((n) => `modelList=${n}`).join('&')}&${workingEfficiencyRequest.activityIds.map((n) => `activityList=${n}`).join('&')}&startDate=${workingEfficiencyRequest.startDate}&endDate=${workingEfficiencyRequest.endDate}`,);
    return result.data;
}

export const getMovementData = async (movementEfficiencyRequest: MovementEfficiencyRequest): Promise<EfficiencyData[]> => {
    const result = await Api.get(`/reports/movement-efficiency-v2?${movementEfficiencyRequest.organizationIds.map((n) => `organizationIdList=${n}`).join('&')}&${movementEfficiencyRequest.companyIds.map((n) => `companyIdList=${n}`).join('&')}&${movementEfficiencyRequest.forkliftIds.map((n) => `forkliftIdList=${n}`).join('&')}&${movementEfficiencyRequest.departmentIds.map((n) => `departmentIdList=${n}`).join('&')}&${movementEfficiencyRequest.modelIds.map((n) => `modelList=${n}`).join('&')}&${movementEfficiencyRequest.activityIds.map((n) => `activityList=${n}`).join('&')}&startDate=${movementEfficiencyRequest.startDate}&endDate=${movementEfficiencyRequest.endDate}`);
    return result.data;
}

export const getCompanyShifts = async (companyId: string): Promise<any> => {
    const result = await Api.get(`/companies/${companyId}/get-company-shifts`);
    return result.data;
}

export const updateCompanyShifts = async (shifts: ShiftModel[], weeklyWorkingHours: number, monthlyWorkingHours: number, companyId: string): Promise<any[]> => {
    const result = await Api.put(`/companies/update-company-shifts?companyId=${companyId}&weeklyWorkingHours=${weeklyWorkingHours}&monthlyWorkingHours=${monthlyWorkingHours}`, shifts);
    return result.data;
}