import { AnyAction, combineReducers, configureStore, Reducer } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import globalReducer from '../shared/global-store/global.slice';
import authReducer from '../pages/utillity-pages/login/store/auth.slice';
import workingEfficiencyReducer from '../pages/efficiencies/efficiency/store/workingEfficiency.slice';
import movementEfficiencyReducer from '../pages/efficiencies/efficiency/store/movementEfficiency.slice';
import usageEfficiencyReducer from '../pages/efficiencies/efficiency/store/usageEfficiency.slice';
import adminViewReducer from '../pages/admin-pages/admin-view/store/admin-view.slice';
import realTimeMapViewReducer from '../pages/efficiencies/real-time-map-view/store/real-time-map-view.slice';
import compareEfficiencySlice from '../pages/efficiencies/comparision-analysis/store/comparision-analysis.slice';
import heatmapSlice from '../pages/efficiencies/heatmap/store/heatmap.slice';
import efficiencySummarySlice from '../pages/efficiencies/efficiency-summary/store/efficiency-summary.slice';
import operatorAnalysisSlice from '../pages/operators/operator-analysis/store/operator-analysis.slice';
import operatorComparingSlice from '../pages/operators/operator-comparing/store/operator-comparing.slice';
import alarmSlice from '../pages/alarms/alarm-table/store/alarm.slice';
import alarmReportSlice from '../pages/alarms/alarm-report/store/alarm-reports.slice';
import alarmSetsSlice from '../pages/alarms/alarm-sets/store/alarm-sets.slice';
import forkliftTrackingSlice from '../pages/forklift-tracking/store/forklift-tracking.slice';
import indoorManagementSlice from '../pages/admin-pages/indoor-management/store/indoor-management.slice';
import venueManagementSlice from '../pages/admin-pages/venue-management/store/venue-management.slice';
import userSettingsSlice from '../pages/utillity-pages/user-settings/store/user-settings.slice';
export const PersistanceStoreName = 'mevzu-admin';
const reducers = combineReducers({
    globalState: globalReducer,
    authState: authReducer,
    workingEfficiencyState: workingEfficiencyReducer,
    movementEfficiencyState: movementEfficiencyReducer,
    usageEfficiencyState: usageEfficiencyReducer,
    adminViewState: adminViewReducer,
    realTimeMapViewState: realTimeMapViewReducer,
    compareEfficiencyState: compareEfficiencySlice,
    heatmapState: heatmapSlice,
    efficiencySummaryState: efficiencySummarySlice,
    operatorAnalysisState: operatorAnalysisSlice,
    operatorComparingState: operatorComparingSlice,
    alarmTableState: alarmSlice,
    alarmReportsState: alarmReportSlice,
    alarmSetsState: alarmSetsSlice,
    forkliftTrackingState: forkliftTrackingSlice,
    indoorManagementState: indoorManagementSlice,
    venueManagementState: venueManagementSlice,
    userSettingsState: userSettingsSlice
});
const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
    if (action.type === 'USER_LOGOUT') {
        storage.removeItem('persist:root')
        return reducers(undefined, action);
    }
    return reducers(state, action);
};

const persistConfig = {
    key: PersistanceStoreName,
    storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
    devTools: true,
    middleware: [thunk]
});

export default store;
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()