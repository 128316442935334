import Api from "../../../../shared/services/api"

export const saveData = async (req: any): Promise<number> => {
    let url = '/users/save-settings';
    const params = Object.entries(req)
        .filter(([_, value]) => value !== undefined)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

    if (params) {
        url += `?${params}`;
    }
    const result = await Api.post(url);
    return result.data;
}

export const getAppStatus = async (): Promise<boolean> => {
    const result = await Api.get(`/appStatus`);
    return result.data;
};

export const setAppStatus = async (): Promise<boolean> => {
    const result = await Api.get(`/appconfiguration/set-app-configuration`);
    return result.data;
};