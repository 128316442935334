import { notification } from "antd";
import { HeatmapType } from "../enums/heatmapType.enum";
import { HeatmapData } from "../models/heatmap.model";
import { EfficiencyData as UsageEfficiencyData, UsageEfficiency, UsageEfficiencyRequest, MovementEfficiencyData, WorkingEfficiencyData, MovementEfficiency, WorkingEfficiency, MovementEfficiencyRequest, WorkingEfficiencyRequest } from "../../pages/efficiencies/efficiency/models/efficiency.model";
import store from "../../app/store";
import dayjs, { Dayjs } from "dayjs";
import * as XLSX from 'xlsx';
import { Forklift } from "../models/forklift.model";
import { ForkliftTag } from "../../pages/admin-pages/admin-view/models/tag.model";
import { Department } from "../models/department.model";
import { Company } from "../models/company.model";
import { TFunction } from "i18next";
import { useMutation } from "react-query";
import { getCompanyShifts, getMovementData } from "../../pages/efficiencies/efficiency/services/efficiency.service";
import { setMovementEfficiency, setOrganizations as setOrganizationsMovement, setForklfts as setForklftsMovement, setDepartments as setDepartmentsMovement, setCompanies as setCompaniesMovement, setTags as setTagsMovement, setModels as setModelsMovement, setActivities as setActivitiesMovement, setPeriod as setPeriodMovement, setHeatmapType as setHeatmapTypeMovement, setShowFilters as setShowFiltersMovement, setShifts, setWeeklyWorkingHours, setMonthlyWorkingHours } from "../../pages/efficiencies/efficiency/store/movementEfficiency.slice";
import { getWorkingData } from "../../pages/efficiencies/efficiency/services/efficiency.service";
import { setWorkingEfficiency, setOrganizations as setOrganizationsWorking, setForklfts as setForklftsWorking, setDepartments as setDepartmentsWorking, setCompanies as setCompaniesWorking, setTags as setTagsWorking, setModels as setModelsWorking, setActivities as setActivitiesWorking, setPeriod as setPeriodWorking, setHeatmapType as setHeatmapTypeWorking, setShowFilters as setShowFiltersWorking } from "../../pages/efficiencies/efficiency/store/workingEfficiency.slice";
import { getUsageData } from "../../pages/efficiencies/efficiency/services/efficiency.service";
import { setUsageEfficiency, setOrganizations as setOrganizationsUsage, setForklfts as setForklftsUsage, setDepartments as setDepartmentsUsage, setCompanies as setCompaniesUsage, setTags as setTagsUsage, setModels as setModelsUsage, setActivities as setActivitiesUsage, setPeriod as setPeriodUsage, setHeatmapType as setHeatmapTypeUsage, setShowFilters as setShowFiltersUsage } from "../../pages/efficiencies/efficiency/store/usageEfficiency.slice";
import { TimePeriod } from "../enums/timePeriods.enum";
import { EfficienyType } from "../enums/efficiencyType.enum";
import { EfficiencySummary } from "../../pages/efficiencies/efficiency-summary/models/efficiencySummary.model";
import duration from 'dayjs/plugin/duration';
import { ShiftModel } from "../models/shift.model";
import { Organization } from "../models/organization.model";
import { saveData } from "../../pages/utillity-pages/user-settings/services/userSettings.service";
dayjs.extend(duration);

type NotificationType = 'success' | 'info' | 'warning' | 'error';

export const openNotification = (type: NotificationType, message: string, description: string) => {
    if (store.getState().globalState.showNotif){
        notification[type]({
            message: message,
            description: description
        });
    }
};

export const prepareHeatmapLabels = (
    heatmapType: HeatmapType, 
    shifts?: ShiftModel[]
) => {
    let labels = [];
    
    if (heatmapType === HeatmapType.Hourly) {
        for (let i = 0; i < 24; i++) {
            labels.push(`${('0' + i).slice(-2)}:00 - ${('0' + ((i + 1) % 24)).slice(-2)}:00`);
        }
    } else if (shifts && shifts.length > 0) {
        shifts.forEach(shift => {
            const start = shift.startDate?.slice(0, 5);
            const end = shift.endDate?.slice(0, 5);
            labels.push(`${start} - ${end}`);
        });
    }
    
    return labels.reverse();
};


export const getHeatmapData = (heatmapData: HeatmapData[], heatmapType: HeatmapType, shifts: ShiftModel[]) => {
    if (heatmapData === null || heatmapData.length === 0) {
        return;
    }

    let labels = prepareHeatmapLabels(heatmapType, shifts);

    let tempData: any = [];

    labels.forEach((label) => {
        let hourlyData = {
            name: label,
            data: [],
        }
        tempData.push(hourlyData)
    });

    heatmapData.forEach(dailyData => {
        dailyData.value.forEach((value, index) => {
            tempData[index]?.data.push({ x: dailyData.date, y: value > 100 ? 100 : value === null ? -1 : value })
        })
    });

    return tempData;
}

export const getUserInitials = (userName: string) => {
    return userName.split(" ").map((n)=>n[0]).join("");
}

export const getProcessedWorkingData = (startDateStr: string, endDateStr: string, data: WorkingEfficiencyData[]) : WorkingEfficiency => {
    let localData = data.map( x => {
        x.startDate = dayjs.utc(x.startDate).local()
        return x
    });
    let hourlyWorkingEfficiency: HeatmapData[] = [];
    let shiftBasedMovementEfficiency: HeatmapData[] = [];
    let workingAsMinutes: number[][] = [];
    let operators: string[][] = [];
    let operatorsShift: string[][] = [];
    let workingAsMinutesShift: number[][] = [];
    let startDate = dayjs(startDateStr);
    let endDate = dayjs(endDateStr);
    for (var d = startDate; d.isBefore(endDate); d = d.add(1, 'day')) {
        let dayData = localData.filter(x => dayjs(x.startDate).isSame(d, 'date'));
        let dayValues: number[] = [];
        let workingAsMinutesInDay: number[] = [];
        let operatorsInDay: string[] = [];
        for(let i = 0; i < 24; i++){
            let hourlyData = dayData.filter(x => dayjs(x.startDate).minute(0).second(0).millisecond(0).isSame(d.hour(i).minute(0).second(0).millisecond(0)))
            if(hourlyData.length > 0){
                dayValues.push(hourlyData[0].efficiency)
                workingAsMinutesInDay.push(hourlyData[0].workingAsMinute);
                operatorsInDay.push(hourlyData[0].operators);
            }else{
                dayValues.push(0)
                workingAsMinutesInDay.push(0);
                operatorsInDay.push("");
            }
        }
        workingAsMinutes.push(workingAsMinutesInDay.reverse());
        operators.push(operatorsInDay.reverse());
        operatorsInDay = operatorsInDay.reverse();
        dayValues = dayValues.reverse();
        hourlyWorkingEfficiency.push({
            date: d.format('LL'),
            value: dayValues
        })
        calculateShiftValuesData(operatorsInDay, operatorsShift, dayValues, workingAsMinutesInDay, workingAsMinutesShift, shiftBasedMovementEfficiency, d)
    }
    return {
        hourlyWorkingEfficiency,
        shiftBasedWorkingEfficiency: shiftBasedMovementEfficiency,
        workingAsMinutes,
        workingAsMinutesShift,
        operators,
        operatorsShift
    } as WorkingEfficiency
}

export const getProcessedMovementData = (startDateStr: string, endDateStr: string, data: MovementEfficiencyData[]) : MovementEfficiency => {
    let hourlyWorkingEfficiency: HeatmapData[] = [];
    let shiftBasedMovementEfficiency: HeatmapData[] = [];
    let localData = data.map( x => {
        x.startDate = dayjs.utc(x.startDate).local()
        return x
    });
    let startDate = dayjs(startDateStr);
    let endDate = dayjs(endDateStr);
    let movementAsMinutes: number[][] = [];
    let movementAsMinutesShift: number[][] = [];
    let operators: string[][] = [];
    let operatorsShift: string[][] = [];
    for (var d = startDate; d.isBefore(endDate); d = d.add(1, 'day')) {
        let dayData = localData.filter(x => dayjs(x.startDate).isSame(d, 'date'));
        let dayValues: number[] = [];
        let movementAsMinutesInDay: number[] = [];
        let operatorsInDay: string[] = [];
        for(let i = 0; i < 24; i++){
            let hourlyData = dayData.filter(x => dayjs(x.startDate).minute(0).second(0).millisecond(0).isSame(d.hour(i).minute(0).second(0).millisecond(0)))
            if(hourlyData.length > 0){
                dayValues.push(hourlyData[0].efficiency)
                movementAsMinutesInDay.push(hourlyData[0].movementAsMinute);
                operatorsInDay.push(hourlyData[0].operators);
            }else{
                dayValues.push(0)
                movementAsMinutesInDay.push(0);
                operatorsInDay.push("");
            }
        }
        movementAsMinutes.push(movementAsMinutesInDay.reverse());
        operators.push(operatorsInDay.reverse());
        operatorsInDay = operatorsInDay.reverse();
        dayValues = dayValues.reverse();
        hourlyWorkingEfficiency.push({
            date: d.format('LL'),
            value: dayValues
        })
        calculateShiftValuesData(operatorsInDay, operatorsShift, dayValues, movementAsMinutesInDay, movementAsMinutesShift, shiftBasedMovementEfficiency, d)
    }
    return {
        hourlyMovementEfficiency: hourlyWorkingEfficiency,
        shiftBasedMovementEfficiency: shiftBasedMovementEfficiency,
        movementAsMinutes: movementAsMinutes,
        movementAsMinutesShift: movementAsMinutesShift,
        operators,
        operatorsShift
    } as MovementEfficiency
}

export const calculateShiftValuesData = (operatorsInDay: string[], operatorsShift: string[][], dayValues: number[], workingAsMinutesInDay: number[], workingAsMinutesShift: number[][], shiftBasedMovementEfficiency: HeatmapData[], d: Dayjs) => {
    let shifts = store.getState().movementEfficiencyState.shifts as ShiftModel[];
    let shiftOperators: any[] = [];
    let shiftValues: any[] = [];
    let shiftMinutes: any[] = [];
    let workingAsMinutesInDayReverse: any[] = [...workingAsMinutesInDay].reverse();
    let operatorsInDayReverse: any[] = [...operatorsInDay].reverse();
    let dayValuesReverse: any[] = [...dayValues].reverse();

    shifts.map((shift, i) => {
        let startHour = Number(shift.startDate.split(':')[0]);
        let startMinutes = Number(shift.startDate.split(':')[1]);
        let endHour = Number(shift.endDate.split(':')[0]);
        let endMinutes = Number(shift.endDate.split(':')[1]);
        let breakDuration = shift.totalBreak;

        if (endMinutes > 0) {
            endHour += 1;
        }

        if (startMinutes > 0) {
            breakDuration += startMinutes;
        }
        
        if (endMinutes > 0) {
            breakDuration += 60 - endMinutes
        }

        if (endHour <= startHour) {
            endHour += 24; // Gece yarısı durumunda endHour'u bir sonraki güne taşır
        }        

        let efficiencyDuration = (endHour - startHour) - (breakDuration / 60)

        shiftMinutes.push(Math.ceil(workingAsMinutesInDayReverse.slice(startHour,endHour).reduce((partialSum, a) => partialSum + a, 0)));

        shiftOperators.push(operatorsInDayReverse.slice(startHour,endHour).filter(x => x === null || x === "" || x === "[]").length === (endHour - startHour) ? "" : operatorsInDayReverse.slice(startHour,endHour).filter(x => x !== "" && x !== "[]").filter((val,id,array) => array.indexOf(val) == id).join(","));

        shiftValues.push(dayValuesReverse.slice(startHour,endHour).filter(x => x === null).length === (endHour - startHour) ? null : Math.ceil(dayValuesReverse.slice(startHour,endHour).reduce((partialSum, a) => partialSum + a, 0) / efficiencyDuration));
    })

    workingAsMinutesShift.push(shiftMinutes.reverse());

    operatorsShift.push(shiftOperators.reverse());
    
    shiftBasedMovementEfficiency.push({
        date: d.format('LL'),
        value: shiftValues.reverse()
    })
}

export const getProcessedUsageData = (startDateStr: string, endDateStr: string, data: UsageEfficiencyData[]) : UsageEfficiency => {
    let hourlyWorkingEfficiency: HeatmapData[] = [];
    let shiftBasedUsageEfficiency: HeatmapData[] = [];
    let localData = data.map( x => {
        x.startDate = dayjs.utc(x.startDate).local()
        return x
    });
    let startDate = dayjs(startDateStr);
    let endDate = dayjs(endDateStr);
    let movementAsMinutes: number[][] = [];
    let workingAsMinutes: number[][] = [];
    let workingAsMinutesShift: number[][] = [];
    let movementAsMinutesShift: number[][] = [];
    let operators: string[][] = [];
    let operatorsShift: string[][] = [];
    for (var d = startDate; d.isBefore(endDate); d = d.add(1, 'day')) {
        let dayData = localData.filter(x => dayjs(x.startDate).isSame(d, 'date'));
        let dayValues: number[] = [];
        let movementAsMinutesInDay: number[] = [];
        let workingAsMinutesInDay: number[] = [];
        let operatorsInDay: string[] = [];
        for(let i = 0; i < 24; i++){
            let hourlyData = dayData.filter(x => dayjs(x.startDate).minute(0).second(0).millisecond(0).isSame(d.hour(i).minute(0).second(0).millisecond(0)))
            if(hourlyData.length > 0){
                if (!!hourlyData[0].efficiency) {
                    dayValues.push(100 - hourlyData[0].efficiency)
                } else {
                    dayValues.push(hourlyData[0].efficiency)
                }
                movementAsMinutesInDay.push(hourlyData[0].movementAsMinute);
                workingAsMinutesInDay.push(hourlyData[0].workingAsMinute);
                operatorsInDay.push(hourlyData[0].operators);
            }else{
                dayValues.push(0)
                movementAsMinutesInDay.push(0);
                workingAsMinutesInDay.push(0);
                operatorsInDay.push("");
            }
        }
        movementAsMinutes.push(movementAsMinutesInDay.reverse());
        workingAsMinutes.push(workingAsMinutesInDay.reverse());
        operators.push(operatorsInDay.reverse());
        operatorsInDay = operatorsInDay.reverse();
        dayValues = dayValues.reverse();
        hourlyWorkingEfficiency.push({
            date: d.format('LL'),
            value: dayValues
        })
        calculateShiftValuesUsageData(operatorsInDay, operatorsShift, dayValues, workingAsMinutesInDay, workingAsMinutesShift, movementAsMinutesInDay, movementAsMinutesShift, shiftBasedUsageEfficiency, d)
    }
    return {
        hourlyUsageEfficiency: hourlyWorkingEfficiency,
        shiftBasedUsageEfficiency: shiftBasedUsageEfficiency,
        workingAsMinutes: workingAsMinutes,
        workingAsMinutesShift: workingAsMinutesShift,
        movementAsMinutes: movementAsMinutes,
        movementAsMinutesShift: movementAsMinutesShift,
        operators,
        operatorsShift
    } as UsageEfficiency
}

export const calculateShiftValuesUsageData = (operatorsInDay: string[], operatorsShift: string[][], dayValues: number[], workingAsMinutesInDay: number[], workingAsMinutesShift: number[][], movementAsMinutesInDay: number[], movementAsMinutesShift: number[][], shiftBasedMovementEfficiency: HeatmapData[], d: Dayjs) => {
    let shifts = store.getState().movementEfficiencyState.shifts as ShiftModel[];
    let shiftOperators: any[] = [];
    let shiftValues: any[] = [];
    let shiftWorkingMinutes: any[] = [];
    let shiftMovementMinutes: any[] = [];
    let workingAsMinutesInDayReverse: any[] = [...workingAsMinutesInDay].reverse();
    let movementAsMinutesInDayReverse: any[] = [...movementAsMinutesInDay].reverse();
    let operatorsInDayReverse: any[] = [...operatorsInDay].reverse();
    let dayValuesReverse: any[] = [...dayValues].reverse();

    shifts.map((shift, i) => {
        let startHour = Number(shift.startDate.split(':')[0]);
        let endHour = Number(shift.endDate.split(':')[0]);
        let endMinutes = Number(shift.endDate.split(':')[1]);

        if (endMinutes > 0) {
            endHour += 1;
        }

        if (endHour <= startHour) {
            endHour += 24; // Gece yarısı durumunda endHour'u bir sonraki güne taşır
        }

        shiftWorkingMinutes.push(Math.ceil(workingAsMinutesInDayReverse.slice(startHour,endHour).reduce((partialSum, a) => partialSum + a, 0)));
        shiftMovementMinutes.push(Math.ceil(movementAsMinutesInDayReverse.slice(startHour,endHour).reduce((partialSum, a) => partialSum + a, 0)));

        shiftOperators.push(operatorsInDayReverse.slice(startHour,endHour).filter(x => x === null || x === "" || x === "[]").length === (endHour - startHour) ? "" : operatorsInDayReverse.slice(startHour,endHour).filter(x => x !== "" && x !== "[]").filter((val,id,array) => array.indexOf(val) == id).join(","));
    })

    workingAsMinutesShift.push(shiftWorkingMinutes.reverse());
    movementAsMinutesShift.push(shiftMovementMinutes.reverse());

    operatorsShift.push(shiftOperators.reverse());

    shifts.map((_, i) => {
        if (!!shiftWorkingMinutes[i] && shiftWorkingMinutes[i] !== 0) {
            shiftValues.push(100 - Math.ceil(shiftMovementMinutes[i] * 100 / shiftWorkingMinutes[i]));
        } else if (!!shiftWorkingMinutes && shiftWorkingMinutes[i] === 0) {
            shiftValues.push(null);
        } else {
            shiftValues.push(null);
        }
    })
    
    shiftBasedMovementEfficiency.push({
        date: d.format('LL'),
        value: shiftValues
    })
}

export const getTitle = (type: string, forklifts: string[], allForklifts: Forklift[], tags: string[], allTags: ForkliftTag[], models: string[], allModels: any[], activities: string[], allActivites: any[], departments: string[], allDepartments: Department[], companies: string[], allCompanies: Company[], organizations: string[], allOrganizations: Organization[], heatmapType: HeatmapType, startDate: string, endDate: string, t: TFunction<"translation", undefined, "translation">) => {
    if(forklifts.length > 0) {
        let reportVariables = (allForklifts.filter(x => forklifts.includes(x.id)).map(x => x.name).join(', '))
        return `${t("menu." + type)} ${reportVariables} ${heatmapType === HeatmapType.Hourly ? t("common.hourly") : t("common.shift_based")} ${startDate} - ${endDate}`
    }

    if(tags.length > 0) {
        let reportVariables = (allTags.filter(x => tags.includes(x.id)).map(x => x.name).join(', '))
        return `${t("menu." + type)} ${reportVariables} ${heatmapType === HeatmapType.Hourly ? t("common.hourly") : t("common.shift_based")} ${startDate} - ${endDate}`
    }

    if(models.length > 0) {
        let reportVariables = (allModels.filter(x => models.includes(x.id)).map(x => x.name).join(', '))
        return `${t("menu." + type)} ${reportVariables} ${heatmapType === HeatmapType.Hourly ? t("common.hourly") : t("common.shift_based")} ${startDate} - ${endDate}`
    }
    
    if(activities.length > 0) {
        let reportVariables = (allActivites.filter(x => activities.includes(x.id)).map(x => x.name).join(', '))
        return `${t("menu." + type)} ${reportVariables} ${heatmapType === HeatmapType.Hourly ? t("common.hourly") : t("common.shift_based")} ${startDate} - ${endDate}`
    }

    if(departments.length > 0) {
        let reportVariables = (allDepartments.filter(x => departments.includes(x.id)).map(x => x.name).join(', '))
        return `${t("menu." + type)} ${reportVariables} ${heatmapType === HeatmapType.Hourly ? t("common.hourly") : t("common.shift_based")} ${startDate} - ${endDate}`
    }

    if(companies.length > 0) {
        let reportVariables = (allCompanies.filter(x => companies.includes(x.id)).map(x => x.name).join(', '))
        return `${t("menu." + type)} ${reportVariables} ${heatmapType === HeatmapType.Hourly ? t("common.hourly") : t("common.shift_based")} ${startDate} - ${endDate}`
    }

    if(organizations.length > 0) {
        let reportVariables = (allOrganizations.filter(x => organizations.includes(x.id)).map(x => x.name).join(', '))
        return `${t("menu." + type)} ${reportVariables} ${heatmapType === HeatmapType.Hourly ? t("common.hourly") : t("common.shift_based")} ${startDate} - ${endDate}`
    }
}

export const efficiencyTypes = (t: TFunction<"translation", undefined, "translation">) => {
    return [{
        label: t("common.hourly"),
        value: HeatmapType.Hourly
    }, {
        label: t("common.shift_based"),
        value: HeatmapType.Shift
    }];
}

export const efficiencyForceRender = (setForceRender: React.Dispatch<React.SetStateAction<boolean>>) => {
    setForceRender(true);
    setTimeout(() => {setForceRender(false)},500);
}

export const efficiencySubmit = (values: any, setStartDate: (value: React.SetStateAction<string>) => void, setEndDate: (value: React.SetStateAction<string>) => void, t: TFunction<"translation", undefined, "translation">) => {
    efficiencySetDates(setStartDate, setEndDate, values);
    efficiencyValidation(values, t);
}

export const efficiencySetDates = (setStartDate: (value: React.SetStateAction<string>) => void, setEndDate: (value: React.SetStateAction<string>) => void, values: any) => {
    setStartDate(dayjs(values.date[0]).format('LL'))
    setEndDate(dayjs(values.date[1]).format('LL'))
}

export const efficiencyValidation = (values: any, t: TFunction<"translation", undefined, "translation">) => {
    if(values.forkliftIds.length < 1 && values.departmentIds.length < 1 && values.companyIds.length < 1){
        openNotification('error', t('common.error'), t('common.error_info'));
        return
    }
}

export const efficiencyRequestPrepare = (values: any) => {
    return {
        organizationIds: values.organizationIds as string[],
        companyIds: values.companyIds as string[],
        departmentIds: values.departmentIds as string[],
        forkliftIds: values.forkliftIds as string[],
        activityIds: values.activityIds as string[],
        modelIds: values.modelIds as string[],
        startDate: dayjs(values.date[0]).hour(0).minute(0).second(0).millisecond(0).toISOString(),
        endDate:  dayjs(values.date[1]).hour(23).minute(59).second(59).millisecond(0).toISOString()
    }
}

export const useGetMovementDataMutation = (t: TFunction<"translation", undefined, "translation">) => {
    return useMutation(({ req }: { req: MovementEfficiencyRequest }) => getMovementData(req), {
        onSuccess: (data, variables) => {
            store.dispatch(setMovementEfficiency(getProcessedMovementData(variables.req.startDate, variables.req.endDate, data)))            
        },
        onError: () => {
            openNotification('error', t('common.error'), t('common.error_info'));
        }
    })
}

export const useGetWorkingDataMutation = (t: TFunction<"translation", undefined, "translation">) => {
    return useMutation(({ req }: { req: WorkingEfficiencyRequest }) => getWorkingData(req), {
        onSuccess: (data, variables) => {
            store.dispatch(setWorkingEfficiency(getProcessedWorkingData(variables.req.startDate, variables.req.endDate, data)))            
        },
        onError: () => {
            openNotification('error', t('common.error'), t('common.error_info'));
        }
    })
}

export const useGetUsageDataMutation = (t: TFunction<"translation", undefined, "translation">) => {
    return useMutation(({ req }: { req: UsageEfficiencyRequest }) => getUsageData(req), {
        onSuccess: (data, variables) => {
            store.dispatch(setUsageEfficiency(getProcessedUsageData(variables.req.startDate, variables.req.endDate, data)))            
        },
        onError: () => {
            openNotification('error', t('common.error'), t('common.error_info'));
        }
    })
}

export const useGetComapnyShiftsMutation = (t: TFunction<"translation", undefined, "translation">) => {
    return useMutation(({ req }: { req: string }) => getCompanyShifts(req), {
        onSuccess: (data, variables) => {
            store.dispatch(setShifts(data.shift));       
            store.dispatch(setWeeklyWorkingHours(data.weeklyWorkingHour));       
            store.dispatch(setMonthlyWorkingHours(data.monthlyWorkingHour));       
        },
        onError: () => {
            openNotification('error', t('common.error'), t('common.error_info'));
        }
    })
}

export const efficiencyReset = () => {
    store.dispatch(setPeriodUsage(TimePeriod.Daily));
    store.dispatch(setOrganizationsUsage([]));
    store.dispatch(setCompaniesUsage([]));
    store.dispatch(setDepartmentsUsage([]));
    store.dispatch(setForklftsUsage([]));
    store.dispatch(setTagsUsage([]));
    store.dispatch(setModelsUsage([]));
    store.dispatch(setActivitiesUsage([]));

    store.dispatch(setPeriodMovement(TimePeriod.Daily));
    store.dispatch(setOrganizationsMovement([]));
    store.dispatch(setCompaniesMovement([]));
    store.dispatch(setDepartmentsMovement([]));
    store.dispatch(setForklftsMovement([]));
    store.dispatch(setTagsMovement([]));
    store.dispatch(setModelsMovement([]));
    store.dispatch(setActivitiesMovement([]));

    store.dispatch(setPeriodWorking(TimePeriod.Daily));
    store.dispatch(setOrganizationsWorking([]));
    store.dispatch(setCompaniesWorking([]));
    store.dispatch(setDepartmentsWorking([]));
    store.dispatch(setForklftsWorking([]));
    store.dispatch(setTagsWorking([]));
    store.dispatch(setModelsWorking([]));
    store.dispatch(setActivitiesWorking([]));
}

export const efficiencySetOrganizations = (companies: string[]) => {
    store.dispatch(setOrganizationsUsage(companies));
    store.dispatch(setOrganizationsMovement(companies));
    store.dispatch(setOrganizationsWorking(companies));
}

export const efficiencySetCompanies = (companies: string[]) => {
    store.dispatch(setCompaniesUsage(companies));
    store.dispatch(setCompaniesMovement(companies));
    store.dispatch(setCompaniesWorking(companies));
}

export const efficiencySetDepartments = (departments: string[]) => {
    store.dispatch(setDepartmentsUsage(departments));
    store.dispatch(setDepartmentsMovement(departments));
    store.dispatch(setDepartmentsWorking(departments));
}

export const efficiencySetForklifts = (forklifts: string[]) => {
    store.dispatch(setForklftsUsage(forklifts));
    store.dispatch(setForklftsMovement(forklifts));
    store.dispatch(setForklftsWorking(forklifts));
}

export const efficiencySetTags = (tags: string[]) => {
    store.dispatch(setTagsUsage(tags));
    store.dispatch(setTagsMovement(tags));
    store.dispatch(setTagsWorking(tags));
}

export const efficiencySetModels = (models: string[]) => {
    store.dispatch(setModelsUsage(models));
    store.dispatch(setModelsMovement(models));
    store.dispatch(setModelsWorking(models));
}

export const efficiencySetActivities = (models: string[]) => {
    store.dispatch(setActivitiesUsage(models));
    store.dispatch(setActivitiesMovement(models));
    store.dispatch(setActivitiesWorking(models));
}

export const efficiencySetPeriod = (period: TimePeriod) => {
    store.dispatch(setPeriodUsage(period));
    store.dispatch(setPeriodMovement(period));
    store.dispatch(setPeriodWorking(period));
}

export const efficiencySetHeatmapType = (heatmapType: HeatmapType) => {
    store.dispatch(setHeatmapTypeUsage(heatmapType));
    store.dispatch(setHeatmapTypeMovement(heatmapType));
    store.dispatch(setHeatmapTypeWorking(heatmapType));
}

export const efficiencySetShowFilters = (showFilters: boolean) => {
    store.dispatch(setShowFiltersUsage(showFilters));
    store.dispatch(setShowFiltersMovement(showFilters));
    store.dispatch(setShowFiltersWorking(showFilters));
}

export const efficiencySetDefaults = () => {
    store.dispatch(setPeriodUsage(TimePeriod.Daily))
    store.dispatch(setPeriodMovement(TimePeriod.Daily))
    store.dispatch(setPeriodWorking(TimePeriod.Daily))
    store.dispatch(setHeatmapTypeUsage(HeatmapType.Hourly))
    store.dispatch(setHeatmapTypeMovement(HeatmapType.Hourly))
    store.dispatch(setHeatmapTypeWorking(HeatmapType.Hourly))
    store.dispatch(setShowFiltersUsage(true))
    store.dispatch(setShowFiltersMovement(true))
    store.dispatch(setShowFiltersWorking(true))
}

export const efficiencyGetType = (efficiencyType: EfficienyType) => {
    switch (efficiencyType) {
        case EfficienyType.Usage:
            return 'usageEfficieny';
        case EfficienyType.Working:
            return 'workingEfficieny';
        case EfficienyType.Movement:
            return 'movementEfficieny';
        default:
            return '';
    }
}

export const efficienyGetData = (efficienyType: EfficienyType, heatmapType: HeatmapType, usageEfficiency: UsageEfficiency, workingEfficiency: WorkingEfficiency, movementEfficiency: MovementEfficiency) => {
    if(efficienyType === null || heatmapType === null)
        return [];

    switch (efficienyType) {
        case EfficienyType.Usage:
            return heatmapType === HeatmapType.Hourly ? usageEfficiency?.hourlyUsageEfficiency : usageEfficiency?.shiftBasedUsageEfficiency;
        case EfficienyType.Working:
            return heatmapType === HeatmapType.Hourly ? workingEfficiency?.hourlyWorkingEfficiency : workingEfficiency?.shiftBasedWorkingEfficiency;
        case EfficienyType.Movement:
            return heatmapType === HeatmapType.Hourly ? movementEfficiency?.hourlyMovementEfficiency : movementEfficiency?.shiftBasedMovementEfficiency;
        default:
            return heatmapType === HeatmapType.Hourly ? usageEfficiency?.hourlyUsageEfficiency : usageEfficiency?.shiftBasedUsageEfficiency;
    }
}

export const efficienyGetMinutesData = (efficienyType: EfficienyType, heatmapType: HeatmapType, usageEfficiency: UsageEfficiency, workingEfficiency: WorkingEfficiency, movementEfficiency: MovementEfficiency) => {
    if(efficienyType === null || heatmapType === null)
        return [];

    switch (efficienyType) {
        case EfficienyType.Usage:
            return heatmapType === HeatmapType.Hourly ? usageEfficiency?.movementAsMinutes : usageEfficiency?.movementAsMinutesShift;
        case EfficienyType.Working:
            return heatmapType === HeatmapType.Hourly ? workingEfficiency?.workingAsMinutes : workingEfficiency?.workingAsMinutesShift;
        case EfficienyType.Movement:
            return heatmapType === HeatmapType.Hourly ? movementEfficiency?.movementAsMinutes : movementEfficiency?.movementAsMinutesShift;
        default:
            return heatmapType === HeatmapType.Hourly ? usageEfficiency?.movementAsMinutes : usageEfficiency?.movementAsMinutesShift;
    }
}

export const efficienyDownloadExcel = (efficienyType: EfficienyType, heatmapType: HeatmapType, usageEfficiency: UsageEfficiency, workingEfficiency: WorkingEfficiency, movementEfficiency: MovementEfficiency, type: string, forklifts: string[], allForklifts: Forklift[], tags: string[], allTags: ForkliftTag[], models: string[], allModels: any[], activities: string[], allActivites: any[], departments: string[], allDepartments: Department[], companies: string[], allCompanies: Company[], organizations: string[], allOrganizations: Organization[], startDate: string, endDate: string, t: TFunction<"translation", undefined, "translation">) => {
    let downloadData:any = [];
    let workingMinutes:any = [];
    let movementMinutes:any = [];
    let shifts = store.getState().movementEfficiencyState.shifts as any[];
    let efficiencyData = efficienyGetData(efficienyType, heatmapType, usageEfficiency, workingEfficiency, movementEfficiency);
    efficiencyData.forEach(x => {
        let rowData: any = {};
        rowData[t("common.date")] = x.date;
        const reversedArray:any = [];
        for (let i = x.value.length - 1; i >= 0; i--) {
            reversedArray.push(x.value[i]);
        }
        let labels = prepareHeatmapLabels(heatmapType, shifts);
        labels.reverse().forEach((label, index) => {
            rowData[label] = reversedArray[index];
        })
        downloadData.push(rowData);
    });
    const worksheet = XLSX.utils.json_to_sheet(downloadData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Ratio");
    if (efficienyType !== EfficienyType.Movement) {
        efficienyGetMinutesData(EfficienyType.Working, heatmapType, usageEfficiency, workingEfficiency, movementEfficiency).forEach((x, i) => {
            let rowData: any = {};
            rowData[t("common.date")] = efficiencyData[i].date;
            const reversedArray:any = [];
            for (let i = x.length - 1; i >= 0; i--) {
                reversedArray.push(x[i]);
            }
            let labels = prepareHeatmapLabels(heatmapType, shifts);
            labels.reverse().forEach((label, index) => {
                rowData[label] = reversedArray[index];
            })
            workingMinutes.push(rowData);
        });
        const workingWorksheet = XLSX.utils.json_to_sheet(workingMinutes);
        XLSX.utils.book_append_sheet(workbook, workingWorksheet, "Working Minutes");
    }
    
    if (efficienyType !== EfficienyType.Working) {
        efficienyGetMinutesData(EfficienyType.Movement, heatmapType, usageEfficiency, workingEfficiency, movementEfficiency).forEach((x, i) => {
        let rowData: any = {};
        rowData[t("common.date")] = efficiencyData[i].date;
        const reversedArray:any = [];
        for (let i = x.length - 1; i >= 0; i--) {
            reversedArray.push(x[i]);
        }
        let labels = prepareHeatmapLabels(heatmapType, shifts);
        labels.reverse().forEach((label, index) => {
            rowData[label] = reversedArray[index];
        })
        movementMinutes.push(rowData);
        });
        const movementWorksheet = XLSX.utils.json_to_sheet(movementMinutes);
        XLSX.utils.book_append_sheet(workbook, movementWorksheet, "Movement Minutes");
    }

    XLSX.writeFile(workbook, `${getTitle(efficiencyGetType(efficienyType), forklifts, allForklifts, tags, allTags, models, allModels, activities, allActivites, departments, allDepartments, companies, allCompanies, organizations, allOrganizations, heatmapType, startDate, endDate, t)}.xlsx`);
};

export const prepareEfficiencyLabels = (isHeatmap: boolean) => {
    let labels = [];
    labels.push(`Sıra No`);
    labels.push(`Tarih`);
    labels.push(`Forklift İsmi`);
    if (isHeatmap) {
        labels.push(`Toplam Çalışma Dakikası`);
        labels.push(`Hareket Dakikası`);
        labels.push(`Rölanti Dakikası`);
        labels.push(`Operatör`);
    } else {
        labels.push(`Toplam Çalışma Saati`);
        labels.push(`Hareket Saati`);
        labels.push(`Rölanti Saati`);
    }
    labels.push(`Rölanti Oranı`);
    labels.push(`Açıklama`);
    return labels;
}

export const efficienyGetMinutes = (key: TimePeriod, dailyEfficiency: EfficiencySummary[], weeklyEfficiency: EfficiencySummary[], yearlyEfficiency: EfficiencySummary[]): EfficiencySummary[] => {
    if(key === null)
        return [];

    if(key == TimePeriod.Daily) {
        return dailyEfficiency
    } else if(key == TimePeriod.Weekly) {
        return weeklyEfficiency
    } else {
        return yearlyEfficiency
    }
}

export const efficiencyGetDate = (key: TimePeriod, date: dayjs.Dayjs) => {
    if(key === null)
        return '';

    if(key == TimePeriod.Daily) {
        return date.format("DD MMM");
    } else if(key == TimePeriod.Weekly) {
        return `${dayjs(date).format("YYYY")}-${dayjs(date).week()}.`;
    } else {
        return `${dayjs(date).format("YYYY")}-${date.format("MMM")}.`;
    }
}

export const efficiencyGetActualDate = (key: TimePeriod, date: dayjs.Dayjs, i: number, index:number) => {
    if(key == TimePeriod.Daily) {
        return date.add(i, 'days');
    } else if(key == TimePeriod.Weekly) {
        return dayjs(date).add(i, 'weeks');
    } else {
        return date.set('month', 1).add((i-1), 'months');
    }
}

export const customEfficienyDownloadExcel = (key: TimePeriod, dailyEfficiency: EfficiencySummary[], weeklyEfficiency: EfficiencySummary[], yearlyEfficiency: EfficiencySummary[], forklifts: string[], allForklifts: Forklift[], tags: string[], allTags: ForkliftTag[], models: string[], allModels: any[], activities: string[], allActivites: any[], departments: string[], allDepartments: Department[], companies: string[], allCompanies: Company[], organizations: string[], allOrganizations: Organization[], startDateDayJs: dayjs.Dayjs, endDateDayJs: dayjs.Dayjs, startDate: string, endDate: string, t: TFunction<"translation", undefined, "translation">) => {
    let downloadData:any = [];
    let excelData = efficienyGetMinutes(key, dailyEfficiency, weeklyEfficiency, yearlyEfficiency);
    let rowCount = 0;
    for (let index = 0; index < excelData.length; index = index+3) {
        let labels = prepareEfficiencyLabels(false);
        let dayCount = 0;
        let workingDataCopy = [...excelData[index].data];
        let movementDataCopy = [...excelData[index+1].data];
        let workingRealData = workingDataCopy.reverse();
        let movementRealData = movementDataCopy.reverse();
        if(key == TimePeriod.Monthly) {
            for (let i = 0; i <= excelData[index].data.length - 2; i++) {
                let rowData: any = {};
                let forkliftWorking =(excelData[index].data[i]);
                let forkliftMovement = (excelData[index+1].data[i]);
                let forkliftUsage = (excelData[index].data[i] - excelData[index+1].data[i]);
                rowData[labels[0]] = ++rowCount;
                rowData[labels[1]] = efficiencyGetDate(key, efficiencyGetActualDate(key, startDateDayJs, dayCount++, i));
                rowData[labels[2]] = (excelData[index].name).split(" - ")[0];            
                rowData[labels[3]] = getHoursAndMinutes(forkliftWorking);
                rowData[labels[4]] = getHoursAndMinutes(forkliftMovement);
                rowData[labels[5]] = getHoursAndMinutes(forkliftUsage);
                rowData[labels[6]] = ((100 * forkliftUsage) / forkliftWorking).toFixed(2);
                rowData[labels[7]] = '';
                downloadData.push(rowData);
            }
        }else{
            for (let i = excelData[index].data.length - 1; i >= 0; i--) {
                let rowData: any = {};
                let forkliftWorking =(workingRealData[i]);
                let forkliftMovement = (movementRealData[i]);
                let forkliftUsage = (workingRealData[i] - movementRealData[i]);
                rowData[labels[0]] = ++rowCount;
                rowData[labels[1]] = efficiencyGetDate(key, efficiencyGetActualDate(key, startDateDayJs, dayCount++, i));
                rowData[labels[2]] = (excelData[index].name).split(" - ")[0];            
                rowData[labels[3]] = getHoursAndMinutes(forkliftWorking);
                rowData[labels[4]] = getHoursAndMinutes(forkliftMovement);
                rowData[labels[5]] = getHoursAndMinutes(forkliftUsage);
                rowData[labels[6]] = ((100 * forkliftUsage) / forkliftWorking).toFixed(2);
                rowData[labels[7]] = '';
                downloadData.push(rowData);
            }
        }
    }
    const worksheet = XLSX.utils.json_to_sheet(downloadData);
    const workbook = XLSX.utils.book_new();

    // Calculate column widths
    const wscols = calculateColumnWidths(downloadData);
    worksheet['!cols'] = wscols;

    Object.keys(worksheet).forEach(cell => {
        if (cell.startsWith('D') || cell.startsWith('E') || cell.startsWith('F')) {
            // Çalışma, hareket ve rölanti saatlerinin bulunduğu sütunlar
            if (worksheet[cell].v && typeof worksheet[cell].v === "number") {
                worksheet[cell].t = 'n';  // Hücre türü: Sayısal (n)
                worksheet[cell].z = "[h]:mm:ss"; // Hücre formatı: Saat formatı
            }
        }

        if (cell.startsWith('G')) {
            if (worksheet[cell].v && typeof worksheet[cell].v === "number") {
                worksheet[cell].t = 'n';  // Hücre türü: Sayısal (n)
            }
        }
    });    

    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    XLSX.writeFile(workbook, `${getEffiiciencySummaryTitle(forklifts, allForklifts, tags, allTags, models, allModels, activities, allActivites, departments, allDepartments, companies, allCompanies, organizations, allOrganizations, startDate, endDate, t)}.xlsx`);
};

export const getHoursAndMinutes = (value: number) => {
    const hours = Math.floor(value / 60); // Saat
    const minutes = value % 60;          // Dakika
    const seconds = 0;                   // Saniye

    // Zamanı Excel'in desteklediği sayısal formata dönüştür
    return hours / 24 + minutes / 1440 + seconds / 86400; // Excel zaman formatı
};

// Utility function to calculate column widths
const calculateColumnWidths = (data: any) => {
    const wscols = [];
  
    // Iterate over each key in the data objects
    const keys = Object.keys(data[0]);
    for (let i = 0; i < keys.length; i++) {
      let maxWidth = keys[i].length; // Start with header length
      data.forEach((row: any) => {
        const cellLength = row[keys[i]] ? row[keys[i]].toString().length : 10;
        if (cellLength > maxWidth) {
          maxWidth = cellLength;
        }
      });
      wscols.push({ wch: maxWidth });
    }
  
    return wscols;
};

export const getEffiiciencySummaryTitle = (forklifts: string[], allForklifts: Forklift[], tags: string[], allTags: ForkliftTag[], models: string[], allModels: any[], activities: string[], allActivites: any[], departments: string[], allDepartments: Department[], companies: string[], allCompanies: Company[], organizations: string[], allOrganizations: Organization[], startDate: string, endDate: string, t: TFunction<"translation", undefined, "translation">) => {
    if(forklifts.length > 0) {
        let reportVariables = (allForklifts.filter(x => forklifts.includes(x.id)).map(x => x.name).join(', '))
        return `${t("menu.efficiencySummary")} ${reportVariables} ${startDate} - ${endDate}`
    }

    if(tags.length > 0) {
        let reportVariables = (allTags.filter(x => tags.includes(x.id)).map(x => x.name).join(', '))
        return `${t("menu.efficiencySummary")} ${reportVariables} ${startDate} - ${endDate}`
    }

    if(activities.length > 0) {
        let reportVariables = (allActivites.filter(x => activities.includes(x.id)).map(x => x.name).join(', '))
        return `${t("menu.efficiencySummary")} ${reportVariables} ${startDate} - ${endDate}`
    }
    
    if(models.length > 0) {
        let reportVariables = (allModels.filter(x => models.includes(x.id)).map(x => x.name).join(', '))
        return `${t("menu.efficiencySummary")} ${reportVariables} ${startDate} - ${endDate}`
    }

    if(departments.length > 0) {
        let reportVariables = (allDepartments.filter(x => departments.includes(x.id)).map(x => x.name).join(', '))
        return `${t("menu.efficiencySummary")} ${reportVariables} ${startDate} - ${endDate}`
    }

    if(companies.length > 0) {
        let reportVariables = (allCompanies.filter(x => companies.includes(x.id)).map(x => x.name).join(', '))
        return `${t("menu.efficiencySummary")} ${reportVariables} ${startDate} - ${endDate}`
    }

    if(organizations.length > 0) {
        let reportVariables = (allOrganizations.filter(x => organizations.includes(x.id)).map(x => x.name).join(', '))
        return `${t("menu.efficiencySummary")} ${reportVariables} ${startDate} - ${endDate}`
    }
}

export const efficienyHeatmapGetMinutes = (key: HeatmapType, efficienyType: EfficienyType, workingEfficiency: WorkingEfficiency, movementEfficiency: MovementEfficiency, usageEfficiency: UsageEfficiency,): any => {
    if(key === null)
        return [];

    if(key == HeatmapType.Hourly) {
        return {
            movementAsMinutes: movementEfficiency.movementAsMinutes,
            workingAsMinutes: workingEfficiency.workingAsMinutes,
            operators: workingEfficiency.operators
        }
    } else if(key == HeatmapType.Shift) {
        return {
            movementAsMinutes: movementEfficiency.movementAsMinutesShift,
            workingAsMinutes: workingEfficiency.workingAsMinutesShift,
            operators: workingEfficiency.operatorsShift
        }
    }
}

export const useSaveUserSettingsMutation = (t: TFunction<"translation", undefined, "translation">) => {
    return useMutation(({ req }: { req: any }) => saveData(req), {
        onSuccess: (_) => {
            
        },
        onError: () => {
            openNotification('error', t('common.error'), t('common.error_info'));
        }
    })
}