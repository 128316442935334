import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HeatmapType } from '../../../../shared/enums/heatmapType.enum';
import { TimePeriod } from '../../../../shared/enums/timePeriods.enum';
import { UsageEfficiency } from '../models/efficiency.model';
import initialUsageEfficiencySliceState from './usageEfficiency-initial-state';

const usageEfficiencySlice = createSlice({
    name: 'usageEfficiencySlice',
    initialState: initialUsageEfficiencySliceState,
    reducers: {
        setUsageEfficiency(state, { payload }: PayloadAction<UsageEfficiency | null>) {
            state.usageEfficiency = payload;
        },
        setOrganizations(state, { payload }: PayloadAction<string[]>) {
            state.organizations = payload;
        },
        setCompanies(state, { payload }: PayloadAction<string[]>) {
            state.companies = payload;
        },
        setDepartments(state, { payload }: PayloadAction<string[]>) {
            state.departments = payload;
        },
        setForklfts(state, { payload }: PayloadAction<string[]>) {
            state.forklits = payload;
        },
        setTags(state, { payload }: PayloadAction<string[]>) {
            state.tags = payload;
        },
        setModels(state, { payload }: PayloadAction<string[]>) {
            state.models = payload;
        },
        setActivities(state, { payload }: PayloadAction<string[]>) {
            state.activities = payload;
        },
        setCreateDate(state, { payload }: PayloadAction<string>) {
            state.dates.startDate = payload;
        },
        setEndDate(state, { payload }: PayloadAction<string>) {
            state.dates.endDate = payload;
        },
        setPeriod(state, { payload }: PayloadAction<TimePeriod>) {
            state.period = payload;
        },
        setHeatmapType(state, { payload }: PayloadAction<HeatmapType>) {
            state.heatmapType = payload;
        },
        setShowFilters(state, { payload }: PayloadAction<boolean>) {
            state.showFilters = payload;
        }
    }
});

export const {
    setUsageEfficiency,
    setOrganizations,
    setCompanies,
    setForklfts,
    setCreateDate,
    setEndDate,
    setPeriod,
    setHeatmapType,
    setDepartments,
    setShowFilters,
    setTags,
    setModels,
    setActivities
} = usageEfficiencySlice.actions

export default usageEfficiencySlice.reducer