import { DateModel } from "../../../../shared/models/date.model";
import { TimePeriod } from "../../../../shared/enums/timePeriods.enum";
import { HeatmapType } from "../../../../shared/enums/heatmapType.enum";
import { MovementEfficiency } from "../models/efficiency.model";
import { ShiftModel } from "../../../../shared/models/shift.model";

export interface movementEfficiencyInitialState {
    movementEfficiency: MovementEfficiency | null;
    organizations: string[];
    companies: string[];
    departments: string[];
    forklits: string[];
    tags: string[];
    models: string[];
    activities: string[];
    dates: DateModel;
    period: TimePeriod;
    heatmapType: HeatmapType;
    showFilters: boolean;
    shifts: ShiftModel[];
    weeklyWorkingHours: number;
    monthlyWorkingHours: number;
}

const initialMovementEfficiency: movementEfficiencyInitialState = {
    movementEfficiency: null,
    organizations: [],
    companies: [],
    departments: [],
    forklits: [],
    tags: [],
    models: [],
    activities: [],
    dates: {
        startDate: null,
        endDate: null
    },
    period: TimePeriod.Daily,
    heatmapType: HeatmapType.Hourly,
    showFilters: true,
    shifts: [],
    weeklyWorkingHours: 0,
    monthlyWorkingHours: 0
}

export default initialMovementEfficiency