import { DateModel } from "../../../../shared/models/date.model";
import { TimePeriod } from "../../../../shared/enums/timePeriods.enum";
import { UsageEfficiency } from "../models/efficiency.model";
import { HeatmapType } from "../../../../shared/enums/heatmapType.enum";

export interface usageEfficiencyInitialState {
    usageEfficiency: UsageEfficiency | null;
    organizations: string[];
    companies: string[];
    departments: string[];
    forklits: string[];
    tags: string[];
    models: string[],
    activities: string[];
    dates: DateModel;
    period: TimePeriod;
    heatmapType: HeatmapType;
    showFilters: boolean;
}

const initialUsageEfficiency: usageEfficiencyInitialState = {
    usageEfficiency: null,
    organizations: [],
    companies: [],
    departments: [],
    forklits: [],
    tags: [],
    models: [],
    activities: [],
    dates: {
        startDate: null,
        endDate: null
    },
    period: TimePeriod.Daily,
    heatmapType: HeatmapType.Hourly,
    showFilters: true
}

export default initialUsageEfficiency