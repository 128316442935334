import store from '../../../app/store';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    // Hata durumunda state'i güncelle
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // Hata detaylarını loglayabilirsiniz (örn. bir izleme servisine)
    console.error('Error caught in ErrorBoundary:', error, errorInfo);
  }

  render(): ReactNode {
    if (this.state.hasError) {
      // Fallback UI göster
      return (<ErrorFallback />);
    }

    // Eğer hata yoksa çocuk bileşenleri render et
    return this.props.children;
  }
}

const ErrorFallback: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const logOut = async () => {
    store.dispatch({type: "USER_LOGOUT"});
    navigate('/login');
    localStorage.clear();

    // 2. SessionStorage'ı Temizle
    sessionStorage.clear();

    // 3. Çerezleri Temizle
    const cookies = document.cookie.split(';');
    cookies.forEach((cookie) => {
      const [name] = cookie.split('=');
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });

    // 4. Service Workers'ı ve IndexedDB'yi Temizle
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => registration.unregister());
      });
    }

    if (window.indexedDB) {
      const databases = indexedDB.databases ? await indexedDB.databases() : [];
      databases.forEach((db) => indexedDB.deleteDatabase(db.name!));
    }
    window.location.reload();
  }

  return (
    <div className='w-screen h-screen flex justify-center items-center'>
      <Result status="500" title={t('common.error')} subTitle={t('common.error_info')} extra={[<Button type="primary" key="console" onClick={() => logOut()}>{t('common.go_back')}</Button>]}></Result>
    </div>
  );
};

export default ErrorBoundary;