import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PrivateRoute from './private-route';
import { LoginPath, DashboardPath, WorkingEfficienyPath, MovementEfficienyPath, UsageEfficienyPath, AdminViewPath, RealTimeMapViewPath, ComparisionAnalysisPath, RawDataPath, HeatmapPath, EfficiencySummaryPath, ShiftMovementSummaryPath, OperatorComparingSummaryPath, AlarmsPath, AlarmReportsPath, AlarmSetsPath, ForkliftTrackingPath, IndoorManagementPath, VenueManagementPath, UserSettingsPath, UserAggrementPath, SignUpPath } from './path';
import PublicRoute from './public-route';
import { ConfigProvider, Spin, theme } from 'antd';
import enUS from 'antd/locale/en_US';
import trTR from 'antd/locale/tr_TR';
import { useSelector } from 'react-redux';
import { selectIsLightTheme, selectLang } from '../shared/global-store/global.selectors';
import { LoadingOutlined } from '@ant-design/icons';
import { EfficienyType } from '../shared/enums/efficiencyType.enum';

import dayjs from 'dayjs';
import ErrorBoundary from '../shared/components/error-boundary/error-boundary';

require('dayjs/locale/de')
require('dayjs/locale/tr')
require('dayjs/locale/en')

const Login = React.lazy(() => import('../pages/utillity-pages/login/login'))
const Dashboard = React.lazy(() => import('../pages/utillity-pages/dashboard/dashboard'))
const Efficiency = React.lazy(() => import('../pages/efficiencies/efficiency/efficiency'))
const AdminView = React.lazy(() => import('../pages/admin-pages/admin-view/admin-view'))
const RealTimeMapView = React.lazy(() => import('../pages/efficiencies/real-time-map-view/realTimeMapView'))
const ComparisionAnalysis = React.lazy(() => import('../pages/efficiencies/comparision-analysis/comparisionAnalysis'))
const NotFound = React.lazy(() => import('../pages/utillity-pages/not-found/not-found'))
const RawView = React.lazy(() => import('../pages/admin-pages/raw/raw'))
const HeatmapView = React.lazy(() => import('../pages/efficiencies/heatmap/heatmap'))
const EfficiencySummaryView = React.lazy(() => import('../pages/efficiencies/efficiency-summary/efficiencySummary'))
const OperatorAnalysis = React.lazy(() => import('../pages/operators/operator-analysis/operatorAnalysis'))
const OperatorComparing = React.lazy(() => import('../pages/operators/operator-comparing/operatorComparing'))
const AlarmTable = React.lazy(() => import('../pages/alarms/alarm-table/alarm-table'))
const AlarmReports = React.lazy(() => import('../pages/alarms/alarm-report/alarm-reports'))
const AlarmSets = React.lazy(() => import('../pages/alarms/alarm-sets/alarm-sets'))
const ForkliftTracking = React.lazy(() => import('../pages/forklift-tracking/forklift-tracking'))
const IndoorManagement = React.lazy(() => import('../pages/admin-pages/indoor-management/indoor-management'))
const VenueManagement = React.lazy(() => import('../pages/admin-pages/venue-management/venue-management'))
const UserSettings = React.lazy(() => import('../pages/utillity-pages/user-settings/userSettings'))
const KVKK = React.lazy(() => import('../pages/utillity-pages/user-aggrement/kvkk'))
const Signup = React.lazy(() => import('../pages/utillity-pages/sign-up/signup'))

const App: React.FC = () => {

    const selectedLang = useSelector(selectLang);
    const isLightTheme = useSelector(selectIsLightTheme);

    useEffect(() => {
        dayjs.locale(selectedLang);
    }, []);

    return(
        <>
            <ConfigProvider locale={selectedLang === "tr" ? trTR : enUS} theme={
                {
                    algorithm: isLightTheme ? theme.defaultAlgorithm : theme.darkAlgorithm,
                    token: {
                        colorPrimary: isLightTheme ? '#FF7801' : '#FF9900',
                        colorLink: isLightTheme ? '#FF7801' : '#FF9900'
                    }, 
                    components: {
                        Layout: {
                          siderBg: isLightTheme ? '#FFF' : '#141414',
                          triggerBg: isLightTheme ? '#FFF' : '#141414',
                          lightSiderBg: isLightTheme ? '#FFF' : '#141414',
                          lightTriggerBg: isLightTheme ? '#FFF' : '#141414',
                          headerBg: isLightTheme ? '#FFF' : '#141414',
                        },
                        Menu: {
                            darkItemBg: isLightTheme ? '#FFF' : '#141414',
                            itemBg: isLightTheme ? '#FFF' : '#141414',
                            darkSubMenuItemBg: isLightTheme ? '#f6f6f6' : '#373737',
                            subMenuItemBg: isLightTheme ? '#f6f6f6' : '#373737',
                        }
                    },
                }
            }>
                <BrowserRouter>
                    <Suspense fallback={<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} className="w-screen h-screen flex justify-center items-center" style={{backgroundColor: isLightTheme ? '#FFF' : '#000'}} />}>
                    <ErrorBoundary>
                    <Routes>
                        <Route path={"/"} element={<PrivateRoute />}>
                            <Route path={DashboardPath} element={<Dashboard />} />
                            <Route path={ComparisionAnalysisPath} element={<ComparisionAnalysis />} />
                            <Route path={WorkingEfficienyPath} element={<Efficiency efficienyType={EfficienyType.Working} />} />
                            <Route path={MovementEfficienyPath} element={<Efficiency efficienyType={EfficienyType.Movement} />} />
                            <Route path={UsageEfficienyPath} element={<Efficiency efficienyType={EfficienyType.Usage} />} />
                            <Route path={AdminViewPath} element={<AdminView />} />
                            <Route path={RealTimeMapViewPath} element={<RealTimeMapView />} />
                            <Route path={RawDataPath} element={<RawView />} />
                            <Route path={HeatmapPath} element={<HeatmapView />} />
                            <Route path={EfficiencySummaryPath} element={<EfficiencySummaryView />} />
                            <Route path={ShiftMovementSummaryPath} element={<OperatorAnalysis />} />
                            <Route path={OperatorComparingSummaryPath} element={<OperatorComparing />} />
                            <Route path={AlarmsPath} element={<AlarmTable />} />
                            <Route path={AlarmReportsPath} element={<AlarmReports />} />
                            <Route path={AlarmSetsPath} element={<AlarmSets />} />
                            <Route path={ForkliftTrackingPath} element={<ForkliftTracking />} />
                            <Route path={IndoorManagementPath} element={<IndoorManagement />} />
                            <Route path={VenueManagementPath} element={<VenueManagement />} />
                            <Route path={UserSettingsPath} element={<UserSettings />} />
                            <Route element={<NotFound />} />
                        </Route>

                        <Route path="/" element={<PublicRoute />}>
                            <Route path={LoginPath} element={<Login />} />
                            <Route path={UserAggrementPath} element={<KVKK />} />
                            <Route path={SignUpPath} element={<Signup />} />
                        </Route>

                        <Route path="*" element={<NotFound />} />
                    </Routes>
                    </ErrorBoundary>
                    </Suspense>
                </BrowserRouter>
            </ConfigProvider>
        </>
    );
}

export default App;
