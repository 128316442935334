import { DateModel } from "../../../../shared/models/date.model";
import { TimePeriod } from "../../../../shared/enums/timePeriods.enum";
import { Efficiency } from "../models/comparision-analysis.model";
import { HeatmapType } from "../../../../shared/enums/heatmapType.enum";
import { EfficienyType } from "../../../../shared/enums/efficiencyType.enum";

export interface compareEfficiencyInitialState {
    efficiencies: Efficiency[];
    comparedEfficieny: Efficiency | null;
    companies: string[];
    departments: string[];
    forklits: string[];
    dates: DateModel;
    period: TimePeriod;
    heatmapType: HeatmapType;
    showFilters: boolean;
    compareType: "Company" | "Department" | "Forklift";
    efficiencyType: EfficienyType;
}

const initialCompareEfficiency: compareEfficiencyInitialState = {
    efficiencies: [],
    comparedEfficieny: null,
    companies: [],
    departments: [],
    forklits: [],
    dates: {
        startDate: null,
        endDate: null
    },
    period: TimePeriod.Daily,
    heatmapType: HeatmapType.Hourly,
    showFilters: true,
    compareType: "Department",
    efficiencyType: EfficienyType.Working
}

export default initialCompareEfficiency