export const LoginPath = 'login';
export const DashboardPath = 'dashboard';
export const WorkingEfficienyPath = 'workingEfficieny';
export const MovementEfficienyPath = 'movementEfficieny';
export const UsageEfficienyPath = 'usageEfficieny';
export const AdminViewPath = 'adminView';
export const RealTimeMapViewPath = 'realTimeMapView';
export const ComparisionAnalysisPath = 'comparisionAnalysis';
export const RawDataPath = 'rawData';
export const HeatmapPath = 'heatmap';
export const EfficiencySummaryPath = 'efficiencySummary';
export const ShiftMovementSummaryPath = 'shiftMovementAnalysis';
export const OperatorComparingSummaryPath = 'operatorComparing';
export const AlarmsPath = 'alarms';
export const AlarmSetsPath = 'alarmSets';
export const AlarmReportsPath = 'alarmReports';
export const ForkliftTrackingPath = 'forkliftTracking';
export const IndoorManagementPath = 'indoorManagement';
export const VenueManagementPath = 'venueManagement';
export const UserSettingsPath = 'userSettings';
export const UserAggrementPath = 'userAggrement';
export const SignUpPath = 'signUp';
export const OpeningPagePath = EfficiencySummaryPath;