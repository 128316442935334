import React, { useEffect, useState } from "react";
import { Layout as AntdLayout, Dropdown, theme, Typography } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { selectCompanies, selectIsLoggedIn, selectUserId, selectUserName, selectUserEmail } from "../../pages/utillity-pages/login/store/auth.selectors";
import PrivateSettings from "../components/privateSettings/privateSettings";
import Menu from "../components/menu/menu";
import logo from '../../images/logo.png';
import minLogo from '../../images/login-logo-min.png';
import './layout.scss';
import { selectIsLightTheme, selectIsMenuCollapsed } from "../global-store/global.selectors";
import type { MenuProps } from 'antd';
import store from '../../app/store';
import { useTranslation } from "react-i18next";
import { setIsMenuCollapsed, setNoActionReportCount } from "../global-store/global.slice";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { HttpTransportType, HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { useMutation } from "react-query";
import { getAlarmReportsCount } from "../../pages/alarms/alarm-report/services/alarm-reports.service";
import { openNotification, useSaveUserSettingsMutation } from "../utils/utils";
import Userowl from "userowl";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
dayjs.extend(utc)
dayjs.extend(localizedFormat)
const { Content, Sider } = AntdLayout;
const { Text } = Typography;
interface LayoutProps {
    children: React.ReactNode;
}

const Layout = (props: LayoutProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const saveSettingsMutation = useSaveUserSettingsMutation(t);
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const isLightTheme = useSelector(selectIsLightTheme);
    const isMenuCollapsed = useSelector(selectIsMenuCollapsed);
    const userName = useSelector(selectUserName);
    const userId = useSelector(selectUserId);
    const userEmail = useSelector(selectUserEmail);
    const { token } = theme.useToken();
    const [hubConnection, setHubConnection] = useState<HubConnection>();
    const items: MenuProps['items'] = [
        {
          label: t("auth.clear_cache_and_logout"),
          key: '1',
          onClick: () => {
            store.dispatch({
                type: "USER_LOGOUT"
            })
          }
        }
    ];

    useEffect(() => {
        createHubConnection();
    }, [])

    const createHubConnection = async () => {
        const hubCn = new HubConnectionBuilder().withUrl(`${process.env.REACT_APP_API_ENDPOINT}socket/no-action-report-data`, {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets
        }).withAutomaticReconnect().build()
        
        try {
            await hubCn.start();
            setHubConnection(hubCn)
        } catch (e) {
            console.log("e", e)
        }
    }

    useEffect(()=>{
        if(hubConnection){
            hubConnection.on("Ping",()=>{
                getAlarmReportsCountMutation.mutate({ userId: userId});
            })
        }
    },[hubConnection])

    const getAlarmReportsCountMutation = useMutation(({ userId }: { userId: string }) => getAlarmReportsCount(userId), {
        onSuccess: (data) => {
            dispatch(setNoActionReportCount(data));
        },
        onError: () => {
            openNotification('error', t('common.error'), t('common.error_info'));
        }
    })

    useEffect(() => {
        Userowl.initialize(
          `654ab76bec2f822b24103ee8`          
        );
        Userowl.identify({
          name: userName,
          email: userEmail,
          id: userId,
        });
      }, []);

    const greeting = () => {
        const currentTime = dayjs();
        
        let greeting;
        const hour = currentTime.hour();
        
        if (hour >= 5 && hour < 12) {
            greeting = `${t("greetings.morning")} ${userName}`;
        } else if (hour >= 12 && hour < 18) {
            greeting = `${t("greetings.afternoon")} ${userName}`;
        } else if (hour >= 18 && hour < 21) {
            greeting = `${t("greetings.evening")} ${userName}`;
        } else {
            greeting = `${t("greetings.night")} ${userName}`;
        }
        
        return <div>{greeting}</div>;
    };

    const onCollapse = () => {
        dispatch(setIsMenuCollapsed(isMenuCollapsed));
        saveSettingsMutation.mutate({
            req: {
                isMenuCollapsed: !isMenuCollapsed
            }
        })
    }
    return (
        <div>
            {isLoggedIn ?
                <AntdLayout hasSider={true}>
                    <Sider breakpoint="lg" collapsible collapsed={isMenuCollapsed} theme={isLightTheme ? "light" : "dark"}>
                        <Dropdown menu={{ items }} trigger={['contextMenu']}>
                            {
                                !isMenuCollapsed ? (
                                    <div className="m-4 flex justify-center items-center">
                                        <img src={logo} height={40} alt="logo" />
                                    </div>
                                ) : (
                                    <div className="m-4 flex justify-center items-center">
                                        <img src={minLogo} height={40} alt="logo" />
                                    </div>
                                )
                            }
                        </Dropdown>
                        <Menu />
                        <div className="bottom-12 w-full absolute flex justify-center items-center">
                            {
                                !isMenuCollapsed ? <Text>{greeting()}</Text> : undefined
                            }
                        </div>
                        <div className={"z-10 bottom-0 h-12 bg-[" + token.colorBgContainer + "] w-full absolute flex justify-center items-center cursor-pointer"} onClick={() => onCollapse()}>
                            {
                                isMenuCollapsed ? <RightOutlined style={{color: token.colorBgTextActive}} /> : <LeftOutlined style={{color: token.colorBgTextActive}} />
                            }
                        </div>
                    </Sider>
                    <AntdLayout>
                        <PrivateSettings />
                        <Content className="metis-content">
                            {props.children}
                        </Content>
                    </AntdLayout>
                </AntdLayout>
                :
                <div>
                    {props.children}
                </div>
            }
        </div>
    )
}

export default Layout;