import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialUserSettingsState from './user-settings-initial-state';

const userSettingsSlice = createSlice({
    name: 'userSettingsSlice',
    initialState: initialUserSettingsState,
    reducers: {
        setTotalUser(state, { payload }: PayloadAction<number>) {
            state.count = payload;
        }
    }
});

export const {
    setTotalUser
} = userSettingsSlice.actions

export default userSettingsSlice.reducer