import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HeatmapType } from '../../../../shared/enums/heatmapType.enum';
import { TimePeriod } from '../../../../shared/enums/timePeriods.enum';
import initialWorkingEfficiencySliceState from './workingEfficiency-initial-state';
import { WorkingEfficiency } from '../models/efficiency.model';

const workingEfficiencySlice = createSlice({
    name: 'workingEfficiencySlice',
    initialState: initialWorkingEfficiencySliceState,
    reducers: {
        setWorkingEfficiency(state, { payload }: PayloadAction<WorkingEfficiency | null>) {
            state.workingEfficiency = payload;
        },
        setOrganizations(state, { payload }: PayloadAction<string[]>) {
            state.organizations = payload;
        },
        setCompanies(state, { payload }: PayloadAction<string[]>) {
            state.companies = payload;
        },
        setDepartments(state, { payload }: PayloadAction<string[]>) {
            state.departments = payload;
        },
        setForklfts(state, { payload }: PayloadAction<string[]>) {
            state.forklits = payload;
        },
        setTags(state, { payload }: PayloadAction<string[]>) {
            state.tags = payload;
        },
        setModels(state, { payload }: PayloadAction<string[]>) {
            state.models = payload;
        },
        setActivities(state, { payload }: PayloadAction<string[]>) {
            state.activities = payload;
        },
        setCreateDate(state, { payload }: PayloadAction<string>) {
            state.dates.startDate = payload;
        },
        setEndDate(state, { payload }: PayloadAction<string>) {
            state.dates.endDate = payload;
        },
        setPeriod(state, { payload }: PayloadAction<TimePeriod>) {
            state.period = payload;
        },
        setHeatmapType(state, { payload }: PayloadAction<HeatmapType>) {
            state.heatmapType = payload;
        },
        setShowFilters(state, { payload }: PayloadAction<boolean>) {
            state.showFilters = payload;
        }
    }
});

export const {
    setWorkingEfficiency,
    setOrganizations,
    setCompanies,
    setForklfts,
    setTags,
    setModels,
    setActivities,
    setCreateDate,
    setEndDate,
    setPeriod,
    setHeatmapType,
    setDepartments,
    setShowFilters
} = workingEfficiencySlice.actions

export default workingEfficiencySlice.reducer