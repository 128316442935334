import { Dayjs } from "dayjs";
import { TimePeriod } from "../../../../shared/enums/timePeriods.enum";
import { DateModel } from "../../../../shared/models/date.model";
import { EfficiencySummary, UsageEfficiencySummary } from "../models/efficiencySummary.model";

export interface EfficiencySummaryInitialState {
    selectedKey: TimePeriod;
    dailyEfficiencySummary: EfficiencySummary[];
    weeklyEfficiencySummary: EfficiencySummary[];
    yearlyEfficiencySummary: EfficiencySummary[];
    dailyUsageEfficiencySummary: UsageEfficiencySummary[];
    weeklyUsageEfficiencySummary: UsageEfficiencySummary[];
    yearlyUsageEfficiencySummary: UsageEfficiencySummary[];
    organizations: string[];
    companies: string[];
    departments: string[];
    tags: string[];
    models: string[];
    activities: string[];
    forklits: string[];
    dates: DateModel;
    showFilters: boolean;
    startDate: Dayjs | null;
    endDate: Dayjs | null;
    isAlternative: boolean;
    workingHours: number;
    weeklyWorkingHours: number;
    monthlyWorkingHours: number;
}

const initialEfficiencySummaryState: EfficiencySummaryInitialState = {
    selectedKey: TimePeriod.Daily,
    dailyEfficiencySummary: [],
    weeklyEfficiencySummary: [],
    yearlyEfficiencySummary: [],
    dailyUsageEfficiencySummary: [],
    weeklyUsageEfficiencySummary: [],
    yearlyUsageEfficiencySummary: [],
    organizations: [],
    companies: [],
    departments: [],
    forklits: [],
    tags: [],
    models: [],
    activities: [],
    dates: {
        startDate: null,
        endDate: null
    },
    showFilters: true,
    startDate: null,
    endDate: null,
    isAlternative: false,
    workingHours: 24,
    weeklyWorkingHours: 168,
    monthlyWorkingHours: 720
}

export default initialEfficiencySummaryState