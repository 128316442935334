import { AdminViewPath, ComparisionAnalysisPath, DashboardPath, MovementEfficienyPath, RealTimeMapViewPath, UsageEfficienyPath, WorkingEfficienyPath, RawDataPath, HeatmapPath, EfficiencySummaryPath, OperatorComparingSummaryPath, ShiftMovementSummaryPath, AlarmsPath, AlarmReportsPath, AlarmSetsPath, ForkliftTrackingPath, IndoorManagementPath, VenueManagementPath, TrnaslationsPath } from "../../../../app/path"
import { UserRole } from "../../../enums/userRoles.enum";
import { ClockCircleOutlined, DashboardOutlined, HeatMapOutlined, BarChartOutlined, SettingOutlined, FileZipOutlined, PercentageOutlined } from '@ant-design/icons';
import { MenuItem } from "../models/menuItem.model";
import { FaMapMarkedAlt, FaUncharted, FaCompressAlt, FaUserClock, FaUsers, FaPeopleArrows, FaBell, FaBook, FaList, FaHashtag, FaMapMarked, FaUserLock, FaBuilding, FaRegBuilding } from 'react-icons/fa';
import { Languages } from 'lucide-react';

export const getMenus = (): MenuItem[] => {
    let i = 0;
    return [
        {
            id: ++i,
            label: "superAdmin",
            icon: FaUserLock,
            roles: [UserRole.SuperAdmin],
            isDisabled: false,
            children: [
                {
                    id: ++i,
                    link: RawDataPath,
                    icon: FileZipOutlined,
                    roles: [UserRole.SuperAdmin],
                    isDisabled: false,
                    children: []
                },
                {
                    id: ++i,
                    link: VenueManagementPath,
                    icon: FaBuilding,
                    roles: [UserRole.SuperAdmin],
                    isDisabled: false,
                    children: []
                },
                {
                    id: ++i,
                    link: IndoorManagementPath,
                    icon: FaRegBuilding,
                    roles: [UserRole.SuperAdmin],
                    isDisabled: false,
                    children: []
                },
                {
                    id: ++i,
                    link: TrnaslationsPath,
                    icon: Languages,
                    roles: [UserRole.SuperAdmin],
                    isDisabled: false,
                    children: []
                }
            ]
        },
        {
            id: ++i,
            link: DashboardPath,
            icon: DashboardOutlined,
            roles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.User],
            isDisabled: true,
            children: []
        },
        {
            id: ++i,
            label: "productivyAnalysis",
            icon: FaUncharted,
            roles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.User],
            isDisabled: false,
            children: [
                {
                    id: ++i,
                    link: EfficiencySummaryPath,
                    icon: BarChartOutlined,
                    roles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.User],
                    isDisabled: false,
                    children: []
                },
                {
                    id: ++i,
                    label: "detail",
                    icon: FaUncharted,
                    roles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.User],
                    isDisabled: false,
                    children: [
                        {
                            id: ++i,
                            link: WorkingEfficienyPath,
                            icon: ClockCircleOutlined,
                            roles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.User],
                            isDisabled: false
                        },
                        {
                            id: ++i,
                            link: MovementEfficienyPath,
                            icon: HeatMapOutlined,
                            roles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.User],
                            isDisabled: false
                        },
                        {
                            id: ++i,
                            link: UsageEfficienyPath,
                            icon: PercentageOutlined,
                            roles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.User],
                            isDisabled: false
                        }
                    ]
                }
            ]
        },
        {
            id: ++i,
            link: ComparisionAnalysisPath,
            icon: FaCompressAlt,
            roles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.User],
            isDisabled: false,
            children: []
        },
        {
            id: ++i,
            label: "operatorAnalysis",
            icon: FaUsers,
            roles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.User],
            isDisabled: false,
            children: [
                {
                    id: ++i,
                    link: ShiftMovementSummaryPath,
                    icon: FaUserClock,
                    roles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.User],
                    isDisabled: false,
                    children: []
                },
                {
                    id: ++i,
                    link: OperatorComparingSummaryPath,
                    icon: FaPeopleArrows,
                    roles: [UserRole.SuperAdmin],
                    isDisabled: true,
                    children: []
                }
            ]
        },
        {
            id: ++i,
            label: "alarmsOverview",
            icon: FaBell,
            roles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.User],
            isDisabled: false,
            children: [
                {
                    id: ++i,
                    link: AlarmReportsPath,
                    icon: FaBook,
                    roles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.User],
                    isDisabled: true,
                    children: [],
                    hasNotificationBadge: true
                },
                {
                    id: ++i,
                    link: AlarmSetsPath,
                    icon: FaHashtag,
                    roles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.User],
                    isDisabled: false,
                    children: []
                },
                {
                    id: ++i,
                    link: AlarmsPath,
                    icon: FaList,
                    roles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.User],
                    isDisabled: false,
                    children: []
                }
            ]
        },
        {
            id: ++i,
            link: RealTimeMapViewPath,
            icon: FaMapMarkedAlt,
            roles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.User],
            isDisabled: false,
            children: []
        },
        {
            id: ++i,
            link: ForkliftTrackingPath,
            icon: FaMapMarkedAlt,
            roles: [UserRole.SuperAdmin],
            isDisabled: false,
            children: []
        },
        {
            id: ++i,
            link: HeatmapPath,
            icon: HeatMapOutlined,
            roles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.User],
            isDisabled: false,
            children: []
        },
        {
            id: ++i,
            link: AdminViewPath,
            icon: SettingOutlined,
            roles: [UserRole.SuperAdmin, UserRole.Admin],
            isDisabled: false,
            children: []
        }
    ] as MenuItem[]
}