import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialEfficiencySummaryState from './efficiency-summary-initial-state';
import { EfficiencySummary, UsageEfficiencySummary } from '../models/efficiencySummary.model';
import { TimePeriod } from '../../../../shared/enums/timePeriods.enum';
import { Dayjs } from 'dayjs';

const efficiencySummarySlice = createSlice({
    name: 'efficiencySummarySlice',
    initialState: initialEfficiencySummaryState,
    reducers: {
        setKey(state, { payload }: PayloadAction<TimePeriod>) {
            state.selectedKey = payload;
        },
        setDailyEfficiencySummary(state, { payload }: PayloadAction<EfficiencySummary[]>) {
            state.dailyEfficiencySummary = payload;
            state.weeklyEfficiencySummary = [];
            state.yearlyEfficiencySummary = [];
        },
        setWeeklyEfficiencySummary(state, { payload }: PayloadAction<EfficiencySummary[]>) {
            state.weeklyEfficiencySummary = payload;
            state.dailyEfficiencySummary = [];
            state.yearlyEfficiencySummary = [];
        },
        setYearlyEfficiencySummary(state, { payload }: PayloadAction<EfficiencySummary[]>) {
            state.yearlyEfficiencySummary = payload;
            state.dailyEfficiencySummary = [];
            state.weeklyEfficiencySummary = [];
        },
        setDailyUsageEfficiencySummary(state, { payload }: PayloadAction<UsageEfficiencySummary[]>) {
            state.dailyUsageEfficiencySummary = payload;
            state.weeklyUsageEfficiencySummary = [];
            state.yearlyUsageEfficiencySummary = [];
        },
        setWeeklyUsageEfficiencySummary(state, { payload }: PayloadAction<UsageEfficiencySummary[]>) {
            state.weeklyUsageEfficiencySummary = payload;
            state.dailyUsageEfficiencySummary = [];
            state.yearlyUsageEfficiencySummary = [];
        },
        setYearlyUsageEfficiencySummary(state, { payload }: PayloadAction<UsageEfficiencySummary[]>) {
            state.yearlyUsageEfficiencySummary = payload;
            state.dailyUsageEfficiencySummary = [];
            state.weeklyUsageEfficiencySummary = [];
        },
        setOrganizations(state, { payload }: PayloadAction<string[]>) {
            state.organizations = payload;
        },
        setCompanies(state, { payload }: PayloadAction<string[]>) {
            state.companies = payload;
        },
        setDepartments(state, { payload }: PayloadAction<string[]>) {
            state.departments = payload;
        },
        setForklfts(state, { payload }: PayloadAction<string[]>) {
            state.forklits = payload;
        },
        setCreateDate(state, { payload }: PayloadAction<string>) {
            state.dates.startDate = payload;
        },
        setEndDate(state, { payload }: PayloadAction<string>) {
            state.dates.endDate = payload;
        },
        setShowFilters(state, { payload }: PayloadAction<boolean>) {
            state.showFilters = payload;
        },
        setStartDateDayJS(state, { payload }: PayloadAction<Dayjs>) {
            state.startDate = payload;
        },
        setEndDateDayJS(state, { payload }: PayloadAction<Dayjs>) {
            state.endDate = payload;
        },
        setTags(state, { payload }: PayloadAction<string[]>) {
            state.tags = payload;
        },
        setModels(state, { payload }: PayloadAction<string[]>) {
            state.models = payload;
        },
        setActivities(state, { payload }: PayloadAction<string[]>) {
            state.activities = payload;
        },
        setIsAlternative(state, { payload }: PayloadAction<boolean>) {
            state.isAlternative = payload;
        },
        setWorkingHours(state, { payload }: PayloadAction<number>) {
            state.workingHours = payload;
        },
        setWeeklyWorkingHours(state, { payload }: PayloadAction<number>) {
            state.weeklyWorkingHours = payload;
        },
        setMonthlyWorkingHours(state, { payload }: PayloadAction<number>) {
            state.monthlyWorkingHours = payload;
        }
    }
});

export const {
    setKey,
    setDailyEfficiencySummary,
    setWeeklyEfficiencySummary,
    setYearlyEfficiencySummary,
    setDailyUsageEfficiencySummary,
    setWeeklyUsageEfficiencySummary,
    setYearlyUsageEfficiencySummary,
    setOrganizations,
    setCompanies,
    setForklfts,
    setCreateDate,
    setEndDate,
    setDepartments,
    setShowFilters,
    setStartDateDayJS,
    setEndDateDayJS,
    setTags,
    setModels,
    setActivities,
    setIsAlternative,
    setWorkingHours,
    setWeeklyWorkingHours,
    setMonthlyWorkingHours
} = efficiencySummarySlice.actions

export default efficiencySummarySlice.reducer