import React from 'react';
import { theme } from 'antd';
import { useSelector } from "react-redux";
import { selectUser } from "../../../pages/utillity-pages/login/store/auth.selectors";
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setChangePassword, setToken } from "../../../pages/utillity-pages/login/store/auth.slice";
import i18next from 'i18next';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import { setActiveMenuGroupKey, setActiveMenuItemKey, setIsLightTheme, setLang } from '../../global-store/global.slice';
import { selectIsLightTheme, selectLang } from '../../global-store/global.selectors';
import { FaSun, FaMoon } from 'react-icons/fa';
import store from '../../../app/store';
import { FloatButton } from 'antd';
import { PoweroffOutlined, KeyOutlined, UserOutlined, SettingOutlined} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useSaveUserSettingsMutation } from '../../utils/utils';

const PrivateSettings = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const saveSettingsMutation = useSaveUserSettingsMutation(t);
    const isLightTheme = useSelector(selectIsLightTheme);
    const user = useSelector(selectUser);
    const { token } = theme.useToken();
    const selectedLang = useSelector(selectLang);

    const contentStyle = {
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
        boxShadow: token.boxShadowSecondary,
        colorBgContainer: token.colorBgContainer,
        colorPrimary: token.colorPrimary,
        colorWarning: token.colorWarning,
        colorError: token.colorError,
        colorYellow: token['yellow-5'],
        colorBlue: token['blue-10']
    };

    const menuActions = (key: string) => {
        switch (key) {
            case 'logout':
                dispatch(setToken(null))
                break;
            case 'change_password':
                dispatch(setChangePassword(user.email))
                break;
            case 'user_settings':
                dispatch(setActiveMenuGroupKey(null))
                dispatch(setActiveMenuItemKey(-1))
                navigate('userSettings')
                break;
            case 'clear_cache_and_logout':
                store.dispatch({
                    type: "USER_LOGOUT"
                })
                break;
            case 'change_theme':
                saveSettingsMutation.mutate({ req: {
                    isLightTheme: !isLightTheme
                }})
                dispatch(setIsLightTheme(!isLightTheme))
                break;
            default:
                break;
        }
    };

    const languangeChange = () => {
        let key = selectedLang === "tr" ? 'en' : selectedLang === "en" ? "de" : 'tr';
        dayjs.locale(key);
        dispatch(setLang(key));
        i18next.changeLanguage(key);
    };

    const getLanguageTooltip = () => {
        return selectedLang === "tr" ? t("languages.en") : selectedLang === "en" ? t("languages.de") : t("languages.tr")
    }

    const getLanguageDescription = () => {
        return selectedLang === "tr" ? "EN": selectedLang === "en" ? "DE": "TR"
    }

    return (
        <div style={{zIndex: 1000}}>
            <FloatButton.Group shape="circle" icon={<UserOutlined/>} style={{ right: 24 }} trigger="hover" type="primary">
                <FloatButton tooltip={<div>{!isLightTheme ? t("auth.light") : t("auth.dark")}</div>} icon={!isLightTheme ? <FaSun style={{color: contentStyle.colorYellow}}/> : <FaMoon style={{color: contentStyle.colorBlue}}/>} onClick={() => menuActions('change_theme')} />
                <FloatButton tooltip={<div>{getLanguageTooltip()}</div>} description={getLanguageDescription()} onClick={() => languangeChange()}/>
                <FloatButton tooltip={<div>{t("auth.change_password")}</div>} icon={<KeyOutlined style={{color: contentStyle.colorPrimary}} />}  onClick={() => menuActions('change_password')}/>
                <FloatButton tooltip={<div>{t("auth.user_settings")}</div>} icon={<SettingOutlined style={{color: contentStyle.colorBlue}} />}  onClick={() => menuActions('user_settings')}/>
                <FloatButton tooltip={<div>{t("auth.logout")}</div>} icon={<PoweroffOutlined style={{color: contentStyle.colorError}} />}  onClick={() => menuActions('logout')}/>
            </FloatButton.Group>
        </div>
    );
}

export default PrivateSettings;