import { RootState } from "../../app/store";
import { createSelector } from "@reduxjs/toolkit";

export const globalState = (state: RootState) => state.globalState;

export const selectLang = createSelector(
    globalState,
    state => state.lang as string
)

export const selectActiveMenuItem = createSelector(
    globalState,
    state => state.activeMenuItemKey as number
)

export const selectActiveMenuGroup = createSelector(
    globalState,
    state => state.activeMenuGroupKey as number | null
)

export const selectIsLightTheme = createSelector(
    globalState,
    state => state.isLightTheme as boolean
)

export const selectIsMenuCollapsed = createSelector(
    globalState,
    state => state.isMenuCollapsed as boolean
)

export const selectIsShowNotif = createSelector(
    globalState,
    state => state.showNotif as boolean
)

export const selectNoActionReportCount = createSelector(
    globalState,
    state => state.noActionReportCount as number
)

export const selectDefaultPageSize = createSelector(
    globalState,
    state => state.defualtPageSize as number
)