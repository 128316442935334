import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HeatmapType } from '../../../../shared/enums/heatmapType.enum';
import { TimePeriod } from '../../../../shared/enums/timePeriods.enum';
import initialMovementEfficiencySliceState from './movementEfficiency-initial-state';
import { MovementEfficiency } from '../models/efficiency.model';
import { ShiftModel } from '../../../../shared/models/shift.model';

const movementEfficiencySlice = createSlice({
    name: 'movementEfficiencySlice',
    initialState: initialMovementEfficiencySliceState,
    reducers: {
        setMovementEfficiency(state, { payload }: PayloadAction<MovementEfficiency | null>) {
            state.movementEfficiency = payload;
        },
        setOrganizations(state, { payload }: PayloadAction<string[]>) {
            state.organizations = payload;
        },
        setCompanies(state, { payload }: PayloadAction<string[]>) {
            state.companies = payload;
        },
        setDepartments(state, { payload }: PayloadAction<string[]>) {
            state.departments = payload;
        },
        setForklfts(state, { payload }: PayloadAction<string[]>) {
            state.forklits = payload;
        },
        setTags(state, { payload }: PayloadAction<string[]>) {
            state.tags = payload;
        },
        setModels(state, { payload }: PayloadAction<string[]>) {
            state.models = payload;
        },
        setActivities(state, { payload }: PayloadAction<string[]>) {
            state.activities = payload;
        },
        setCreateDate(state, { payload }: PayloadAction<string>) {
            state.dates.startDate = payload;
        },
        setEndDate(state, { payload }: PayloadAction<string>) {
            state.dates.endDate = payload;
        },
        setPeriod(state, { payload }: PayloadAction<TimePeriod>) {
            state.period = payload;
        },
        setHeatmapType(state, { payload }: PayloadAction<HeatmapType>) {
            state.heatmapType = payload;
        },
        setShowFilters(state, { payload }: PayloadAction<boolean>) {
            state.showFilters = payload;
        },
        setShifts(state, { payload }: PayloadAction<ShiftModel[]>) {
            state.shifts = payload;
        },
        setWeeklyWorkingHours(state, { payload }: PayloadAction<number>) {
            state.weeklyWorkingHours = payload;
        },
        setMonthlyWorkingHours(state, { payload }: PayloadAction<number>) {
            state.monthlyWorkingHours = payload;
        }
    }
});

export const {
    setShifts,
    setWeeklyWorkingHours,
    setMonthlyWorkingHours,
    setMovementEfficiency,
    setOrganizations,
    setCompanies,
    setForklfts,
    setCreateDate,
    setEndDate,
    setPeriod,
    setHeatmapType,
    setDepartments,
    setShowFilters,
    setTags,
    setModels,
    setActivities
} = movementEfficiencySlice.actions

export default movementEfficiencySlice.reducer